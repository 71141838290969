dmx.Component('bs4-alert', {

    attributes: {
        show: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: 'primary' // primary, secondary, success, danger, warning, info, light, dark
        },

        closable: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            this[this.$node.classList.contains('show') ? 'hide' : 'show']();
        },

        show: function() {
            this.show();
        },

        hide: function() {
            this.hide();
        },

        setType: function(style) {
            this.setType(style);
        },

        setTextContent: function(text) {
            this.setTextContent(text)
        }
    },

    render: function(node) {
        this.$node = node;
        this.$parse();

        node.setAttribute('role', 'alert');
        node.classList.add('alert');

        var self = this;
        this.$closeButton = jQuery('<button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>').on('click.bs.alert', function() {
            self.hide();
        });

        if (!this.props.show) {
            this.$node.style.setProperty('display', 'none');
        }

        this.update({});
    },

    update: function(props) {
        if (props.type != this.props.type) {
            this.setType(this.props.type);
        }

        if (props.show != this.props.show) {
            this[this.props.show ? 'show' : 'hide']();
        }

        if (props.closable != this.props.closable) {
            if (this.props.closable) {
                jQuery(this.$node).append(this.$closeButton);
                this.$node.classList.add('alert-dismissible');
            } else {
                this.$closeButton.detach();
                this.$node.classList.remove('alert-dismissible');
            }
        }
    },

    show: function() {
        jQuery(this.$node).off('transitionend.bs.alert');
        this.$node.style.removeProperty('display');
        this.$node.offsetWidth;
        this.$node.classList.add('show');
    },

    hide: function() {
        jQuery(this.$node).off('transitionend.bs.alert');
        if (this.$node.classList.contains('fade')) {
            jQuery(this.$node).one('transitionend.bs.alert', function() {
                this.style.setProperty('display', 'none');
            });
        } else {
            this.$node.style.setProperty('display', 'none');
        }
        this.$node.classList.remove('show');
    },

    setType: function(type) {
        var types = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].map(function(type) { return 'alert-' + type });
        this.$node.classList.remove.apply(this.$node.classList, types);
        this.$node.classList.add('alert-' + type);
    },

    setTextContent: function(text) {
        this.$closeButton.detach();
        this.$node.textContent = text;
        if (this.props.closable) {
            jQuery(this.$node).append(this.$closeButton);
        }
    },

    beforeDestroy: function() {
        jQuery(this.$closeButton).off('.bs.alert');
    }

});
